var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"mb-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-breadcrumbs',{staticClass:"m-0",attrs:{"items":_vm.pestanias},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("fas fa-chevron-right")])]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-card',{staticClass:"elevation-4"},[_c('v-card-title',[_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"append-icon":"fas fa-search","label":"Buscar registros por folio","single-inline":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.searchVenta.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"mt-4 ml-2",attrs:{"icon":""}},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("fas fa-sync")])],1),_c('v-tooltip',{attrs:{"left":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-4 ml-2",attrs:{"icon":"","color":"success","dark":""},on:{"click":_vm.handleExcelExport}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("fas fa-file-excel")])],1)]}}])},[_c('span',[_vm._v("Reporte de pagos")])]),_c('Columns',{attrs:{"headers":_vm.headers},on:{"update:headers":function($event){_vm.headers=$event}}})],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.filteredHeaders,"items":_vm.ventas,"loading":_vm.loading,"dense":"","loading-text":"Cargando... espera un momento","options":_vm.options,"footer-props":{ 'items-per-page-options': [ 25, 50, 100 ],
                showFirstLastPage: true,
                firstIcon: 'fas fa-angle-double-left',
                lastIcon: 'fas fa-angle-double-right',
                prevIcon: 'fas fa-angle-left',
                nextIcon: 'fas fa-angle-right'
            },"server-items-length":_vm.totalVentas},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('ActionCompra',{attrs:{"item":item,"showDetailVenta":_vm.showDetailVenta,"show-pagos":_vm.showPagos}})]}}],null,true)})],1)],1),(_vm.detalleOpen)?_c('DetalleSale',{attrs:{"detalleOpen":_vm.detalleOpen,"detailsVenta":_vm.detailsVenta,"handleModal":_vm.handleDetalleModal},on:{"update:detalleOpen":function($event){_vm.detalleOpen=$event},"update:detalle-open":function($event){_vm.detalleOpen=$event},"update:detailsVenta":function($event){_vm.detailsVenta=$event},"update:details-venta":function($event){_vm.detailsVenta=$event}}}):_vm._e(),(_vm.dialogExcel)?_c('ExcelExporterPago',{attrs:{"dialog":_vm.dialogExcel,"handleModal":_vm.handleExcelExport,"client":_vm.client},on:{"update:dialog":function($event){_vm.dialogExcel=$event}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }